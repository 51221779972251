<template>
<body>
    <div class="container" v-if="menuData">
        <main>
            <div class="categories" v-if="categorySelected=='show-all'">
                <div v-for="category in categories" :key="category">
                    <button @click="navigateToCategory(category)" class="category-button  width-full">
                        <p class="category-button-text">{{ category }}</p>
                    </button>
                </div>
            </div>
            <div class="categories">
                <div v-for="category in categories" :key="category">
                    <button @click="navigateToCategory(category)" class="category-button  width-full" v-if="categorySelected == category">
                        <p class="category-button-text">{{ category }}</p>
                    </button>
                    <div class="category-items">
                        <div v-if="subcategories">
                            <div class="sub-categories">
                                <div class="menu-items">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="menu-items">
                                <div class="product-component" v-if="categorySelected == category">
                                    <ProductListing @click="activateProductSpotlight(productListing)"
                                        v-for="(productListing, index) in productListingsForCurrentCategory"
                                        :key="index"
                                        :productListing="productListing"
                                    />
                                    <ProductSpotlight  
                                      :productListingSelected="productListingSelected" 
                                      v-if="productListingSelectedToggle" 
                                      @click="activateProductSpotlight(productListingSelected)" 
                                      class="product-spotlight"
                                    />
                                <div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</body>
</template>


<script setup>
import { ref, watch, defineProps } from 'vue';
import { useMenuStore } from '../stores/menuDataStore';
import ProductListing from './ProductListing.vue';
import ProductSpotlight from './ProductSpotlight.vue'
import { useRoute, useRouter } from 'vue-router';

// Get the current route and router instance
const route = useRoute();
const router = useRouter();

//  eslint-disable-next-line
const props = defineProps({
  categorySelected: {
    type: String,
    required: true,
    default: "Appetizers"
  }
});
// Initialize the menu store
const menuStore = useMenuStore();
const menuData = ref(null);
const categories = ref([]);
const menu = ref([]);
let productListingSelected = ref({});
let productListingSelectedToggle = ref(false);
// data variables
let selectedCategoryInfo = ref(null);
let productListingsForCurrentCategory = ref([]);
let subcategories = ref(null);

// Watch for changes in menuStore.menuData
watch(
  () => menuStore.menuData,
  (newMenuData) => {
    if (newMenuData) {
      menuData.value = newMenuData;
      categories.value = newMenuData.categories;
      menu.value = newMenuData.menu;

      // Update selectedCategoryInfo and productListingsForCurrentCategory when menuData changes
      selectedCategoryInfo.value = menu.value.find(categoryInfo => categoryInfo.category === props.categorySelected);
      if (selectedCategoryInfo.value) {
        productListingsForCurrentCategory.value = selectedCategoryInfo.value.items;
      }
    }
  },
  { immediate: true }
);

watch(
  () => route.params.categorySelected,
  (categorySelected) => {
    selectedCategoryInfo.value = menu.value.find(categoryInfo => categoryInfo.category === categorySelected);
    if (selectedCategoryInfo.value) {
    productListingsForCurrentCategory.value = selectedCategoryInfo.value.items;
    }  
  }
);

// methods
function navigateToCategory(category) {
    // Check if the current route matches the target route (CategoryPage) with the same category
    if (route.name === 'CategoryPage' && route.params.categorySelected === category) {
    // If the current route matches, navigate back to the home page
    router.push({ name: 'MenuComponent', params: { categorySelected: null }});
    } else {
    // If not, navigate to the CategoryPage with the selected category
    router.push({ name: 'CategoryPage', params: { categorySelected: category } });
    }
}

function activateProductSpotlight(productListing){
    productListingSelectedToggle.value = !productListingSelectedToggle.value
    productListingSelected.value = productListing
}
</script>



<style scoped>
/* Add your CSS styles here */

.width-full {
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Clash Grotesk', sans-serif;
}

.container {
  max-width: 1000px; /* Adjust maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0; /* Apply padding relative to the container */
}

main {
    padding: 0px;
}

.product-component {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

/* CATEGORY BUTTONS */

.category-button {
    height: 80px;
    border-radius: 0; /* Remove the curve from the button */
    box-shadow: none; /* Remove any box shadow */
    border: none; /* Remove border */
    border-bottom: 2px;
    border-bottom: 2px solid white; /* Add a bottom border with white color */
    background-color: rgba(186, 124, 70, 0.9); /* Blue color with 80% opacity */

    position: sticky;
    top: 80px;
    z-index: 999; /* Ensure it's above other content */
}

.category-button-text {
    font-size: 20px;
    font-weight: bold;
    color: #fff
}


.back-button{
    position: left;
}

</style>
