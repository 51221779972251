<template>
    <div v-if="menuStore.menuData">
      <div class="header-container">
          <div class="wallpaper-container" v-if="wallpaperImageUrl">
            <img :src="wallpaperImageUrl" alt="Logo" class="wallpaper">
          </div>
      </div>
      <img v-if="logoUrl" :src="logoUrl" alt="Logo" class="logo">
      <div class="venue-details">
        <VenueDetails class="venue-details-component"
            :companyName="companyName"
        />
      </div>
    </div>

</template>

<script setup>

import VenueDetails from "./VenueDetails.vue"

//  eslint-disable-next-line
import { onMounted, watch } from 'vue';
import { useMenuStore } from '../stores/menuDataStore';

// Initialize the menu store
const menuStore = useMenuStore();

let wallpaperImageUrl = null
let logoUrl = null
let companyName = null

watch(
  () => menuStore.menuData,
  (menuData) => {
    if (menuData) {
      wallpaperImageUrl = menuData.company_wallpaper_url;
      logoUrl = menuData.company_logo_url
      companyName = menuData.company_name
    }
  },
  { immediate: true }
);

</script>

<style scoped>
/* Add your CSS styles here */

.venue-details-component {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background: white;
    border-radius: 5px;
}

.width-full {
    width: 100%;
}

.logo {
  max-height: 120px;
  position: absolute;
  left: 80%;
  top: 14%;
  transform: translate(-50%, -50%);
  z-index: /* Ensure the logo is above other elements */
}


.header-container {
    position: relative; 
    height: 100px;
    padding: 10px;
    overflow: hidden; /* Hide any overflow from children */
}
/* Wallpaper container */
.wallpaper-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire width of the header */
  height:100%; /* Set height to 50% of the header height */
  z-index: -1; /* Move the wallpaper behind other content */
  overflow: hidden; /* Hide any overflow */
}

/* Wallpaper image */
.wallpaper {
  width: 100%; /* Make the image cover the entire container */
  height: 100%; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container and maintain aspect ratio */
}



</style>
