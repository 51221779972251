<template>
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Restaurant Menu Categories</title>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Clash+Grotesk:wght@400;700&display=swap">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<body v-if="menuStore.menuData">
    <div class="container">
        <main>
            <div>
                <HeaderComponent :wallpaperImageUrl="wallpaperImageUrl"/>
            </div>
            <div class="categories" v-if="menuStore.menuData">
                <CategoryList :categorySelected="'show-all'" />
            </div>
        </main>
        <footer>
            <p>&copy; 2024 Your Restaurant</p>
        </footer>
    </div>
</body>
</html>

</template>

<script setup>
import { onMounted, watch } from 'vue';
import { useMenuStore } from '../stores/menuDataStore';
import CategoryList from './CategoryList.vue';
import HeaderComponent from './HeaderComponent.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

// Initialize the menu store
const menuStore = useMenuStore();
const venueId = route.params.venueId

// Lifecycle hooks
onMounted(() => {
  menuStore.fetchData(venueId);
});

let wallpaperImageUrl = null

watch(
  () => menuStore.menuData,
  (menuData) => {
    if (menuData) {
      wallpaperImageUrl = menuData.company_wallpaper_url
    }
  },
  { immediate: true }
);
</script>


<style scoped>
/* Add your CSS styles here */

.width-full {
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Clash Grotesk', sans-serif;
}

.container {
  max-width: 1000px; /* Adjust maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0; /* Apply padding relative to the container */
}

main {
    padding: 0px;
}


.logo {
    max-width: 100px; /* Adjust the size of the logo */
}



</style>
