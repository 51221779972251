<template>
    <div class="product">
        <!-- <router-link class="product-link" :to="{ name: 'ProductPage', params: { productId: 234 } }"> -->
            <div class="product-listing-space">
                <div class="product-listing-infomation">
                    <div class="product-listing-title">{{productListing.name}}</div>
                    <div v-if="isObject(productListing.price)" class="multiple-prices">
                        <div v-if="productListing.price.small" class="product-listing-price"><span>small: {{productListing.price.small}}</span></div>
                        <div v-if="productListing.price.medium" class="product-listing-price"><span>med: {{productListing.price.medium}}</span></div>
                        <div v-if="productListing.price.large" class="product-listing-price"><span>large: {{productListing.price.large}}</span></div>
                    </div>
                    <div v-else>
                        <div class="product-listing-price"><span>{{productListing.price}}</span></div>
                    </div>
                    <div class="product-listing-description">
                        {{productListing.description}}
                    </div>
                </div>
                <div class="product-listing-image">
                    <div class="product-listing-image-inner-container">
                        <!-- <img src="../assets/example_image.png"> -->
                        <img v-if="props.productListing.image" :src="props.productListing.image">
                    </div>
                </div>
            </div>
        <!-- </router-link> -->
    </div>
</template>

<script setup>

//  eslint-disable-next-line
const props = defineProps({
  productListing: {
    type: Object,
    required: true,
    default: () => {}
  }
});

function isObject(value) {
  return value && typeof value === 'object' && !Array.isArray(value);
}

</script>

<style scoped>
.product {
    width: 100%;
    padding: 15px 0;
    height: 125px;
    max-height: 125px; /* Ensure product height does not exceed specified height */
    border-bottom: 1px dotted #ccc; /* Add a dotted bottom border */
}

.product-listing-space {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 0px;
    height: 125px;
}

.product-listing-infomation {
    width: 100%;
}

.product-listing-image {
    display: flex;
    justify-content: flex-end; /* Align image to the right */
    overflow: hidden; /* Hide any content that overflows the container */
}

.product-listing-image-inner-container{
    height: 112px;
    width: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure image does not overflow the container */
    border-radius: 10px; /* Adjust the value to control the degree of curvature */
}

.product-listing-image img {
    width: 112px;
    height: 112px;
    object-fit: cover; /* Ensure image covers the container while maintaining aspect ratio */
    border-radius: 10px; /* Adjust the value to control the degree of curvature */
}

.product-link {
    text-decoration: none; /* Remove default underline */
    color: black; /* Change default link color */
}

.product-link:visited {
    color: black; /* Change visited link color */
}

.product-link:hover {
    color: black; /* Change link color on hover */
}

.product-link:active {
    color: black; /* Change link color when active (clicked) */
}

.product-listing-title {
    text-align: left;
    margin-bottom: 20px;
    font-weight: bold;
}

.product-listing-description {
    text-align: left;
    font-size: 14px;
}

.product-listing-price {
    text-align: left;
    margin-bottom: 20px;
    font-size: 16px;    
}
.multiple-prices {
  display: flex; /* Use flexbox to align children inline */
  gap: 10px; /* Optional: add some space between the price divs */
}


</style>