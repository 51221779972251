<template>
<body>
    <div class="container">
        <main>
            <div>
                <HeaderComponent :wallpaperImageUrl="wallpaperImageUrl"/>
            </div>
            <div class="scrolling-menu">
                <ScrollingMenu :categorySelected="categorySelected" class="scrolling-menu"/>
            </div>
            <div class="categories">
                <CategoryList :categorySelected="categorySelected"/>
            </div>
        </main>
    </div>
</body>
</template>

<script setup>
import CategoryList from './CategoryList.vue';
import ScrollingMenu from './ScrollingMenu.vue'
import HeaderComponent from './HeaderComponent.vue'
import { defineProps, watch } from 'vue';
import { useMenuStore } from '../stores/menuDataStore';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';



// Define props using the defineProps macro
 // eslint-disable-next-line
const props = defineProps({
  categorySelected: {
    type: String,
    required: true
  }
});


// Initialize the menu store
const menuStore = useMenuStore();

const route = useRoute()
const venueId = route.params.venueId
// Lifecycle hooks
onMounted(() => {
  menuStore.fetchData(venueId);
});



let wallpaperImageUrl = null

watch(
  () => menuStore.menuData,
  (menuData) => {
    if (menuData) {
      wallpaperImageUrl = menuData.company_wallpaper_url
    }
  },
  { immediate: true }
);

</script>

<style scoped>
/* Add your CSS styles here */

.width-full {
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Clash Grotesk', sans-serif;
}

.container {
  max-width: 1000px; /* Adjust maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0; /* Apply padding relative to the container */
}

header, footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

main {
    padding: 0px;
}

.scrolling-menu {
  position: sticky;
  top: 0;
  background-color: white; /* Ensure background color to avoid transparency issues */
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.3); /* Inner shadow effect */

}

/* CATEGORY BUTTONS */


</style>
