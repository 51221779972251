<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useMenuStore } from './stores/menuDataStore';
import { useRoute } from 'vue-router';

// Initialize the menu store
const menuStore = useMenuStore();

const route = useRoute()
const venueId = route.params.venueId
// Lifecycle hooks
onMounted(() => {
  menuStore.fetchData(venueId);
});


// Lifecycle hooks
onMounted(() => {
  if (!menuStore.menuData)
    menuStore.fetchData();
});


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
</style>
