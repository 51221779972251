import { createRouter, createWebHistory } from 'vue-router';
import MenuComponent from './components/MenuComponent.vue';
import CategoryPage from './components/CategoryPage.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import CategoryList from './components/CategoryList.vue';
import ScrollingMenu from './components/ScrollingMenu.vue';


const routes = [
  { 
    path: '/venue/:venueId', 
    component: MenuComponent,
    name: "MenuComponent"
  },
  { path: '/header', component: HeaderComponent },
  { 
    path: '/scrollingmenu',
    name: 'ScrollingMenu',
    component: ScrollingMenu,
    props: true 
  },
  { 
    path: '/categories',
    name: 'CategoryList',
    component: CategoryList,
  },
  {
    path: '/venue/:venueId/category/:categorySelected', // Dynamic segment for category
    name: 'CategoryPage',
    component: CategoryPage,
    props: true // Pass route params as props to CategoryItems component
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});



export default router;
