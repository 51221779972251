<template>
  <div class="menu-container" ref="menuContainer">
    <div class="menu">
        <div v-for="(category, index) in categories" :key="index" :class="{ 'menu-item': true, 'active': categorySelected === category }" @click="navigateToCategory(category)">
            <router-link :to="{ name: 'CategoryPage', params: { categorySelected: category }}" class="router-link">
              <div>
                <span :class="{ 'active-text': categorySelected === category }">{{ category }}</span>
              </div>
            </router-link>
        </div>   
    </div> 
  </div>
</template>

<script setup>
import { ref, watch, toRefs, onMounted} from 'vue';
import { useMenuStore } from '../stores/menuDataStore';
import { useRouter , useRoute} from 'vue-router';

// // Get the current route and router instance
const route = useRoute();
const router = useRouter();
console.log(route.params)
//  eslint-disable-next-line
const props = defineProps({
  categorySelected: {
    type: String,
    required: true,
    default: null
  },
});
const { categorySelected } = toRefs(props);

// Initialize the menu store
const menuStore = useMenuStore();
const menuData = ref(null);
const categories = ref([]);
const menuContainer = ref(null);

// Watch for changes in menuStore.menuData
watch(
  () => menuStore.menuData,
  (newMenuData) => {
    if (newMenuData) {
      menuData.value = newMenuData;
      categories.value = newMenuData.categories;
    }
  },
  { immediate: true }
);

watch(
  () => route.params.categorySelected,
  (newCategory) => {
    scrollToCategory(newCategory);
  }
);

// methods
function navigateToCategory(category) {
    // Check if the current route matches the target route (CategoryPage) with the same category
    if (route.name === 'CategoryPage' && route.params.categorySelected === category) {
    // do nothing
    } else {
    // If not, navigate to the CategoryPage with the selected category
    router.push({ name: 'CategoryPage', params: { categorySelected: category } });
    }
}
function scrollToCategory(category) {
  if (category && menuContainer.value) {
    const categoryIndex = categories.value.indexOf(category);
    console.log("scrollToCategory: ", categoryIndex)
    if (categoryIndex !== -1) {
      const menuItems = menuContainer.value.querySelectorAll('.menu-item');
      const targetItem = menuItems[categoryIndex];
      if (targetItem) {
        // const containerWidth = menuContainer.value.clientWidth;
        const targetOffsetLeft = targetItem.offsetLeft;
        // const targetWidth = targetItem.clientWidth;
        // const scrollPosition = targetOffsetLeft - containerWidth / 2 + targetWidth / 2;
        menuContainer.value.scrollTo({left: targetOffsetLeft, behavior: 'smooth', duration: 500 });
        console.log(targetOffsetLeft)
      }
    }
  }
}

onMounted(() => {
  scrollToCategory(route.params.categorySelected)
  scrollToComponent();
  window.scrollTo({ top: 0, behavior: 'smooth' });
}
);

function scrollToComponent(){
  if (menuContainer.value) {
    menuContainer.value.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
</script>

<style scoped>

.menu-container {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

/*
.inner-container {
  width: 300%; 
}
*/

.menu {
  display: flex;
  width: 300%; /* Adjust the width of the container as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
  height: 80px
}

/* Optionally, you can style individual menu items */
.menu-item {
  width: 10000px; /* Adjust width as needed */
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(186, 124, 70, 0.9); /* Border color */
  border-bottom: 1px solid rgba(186, 124, 70, 0.9); /* Border color */
  border-right: 1px solid rgba(186, 124, 70, 0.9); /* Border color */
  display: flex;
  font-weight: 600;
}

.active {
  background-color: rgba(186, 124, 70, 0.9);; /* Set background color for active item */
  border-bottom: 0px solid rgba(186, 124, 70, 0.9);
}

.active-text {
  color: white; /* Set text color for active item */
}

/* Hide scrollbar */
.menu-container::-webkit-scrollbar {
  width: 0;
}

/* Optional: Hide scrollbar on Firefox */
.menu-container {
  scrollbar-width: none;
}

.router-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}
</style>