// menuStore.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useMenuStore = defineStore('menu', {
  state: () => ({
    menuData: null,
  }),
  actions: {
    async fetchData(venueId) {
      if (this.menuData || !venueId){
        // If menuData is already populated, do not fetch again
        return;
      }
      console.log("venue id", venueId)
      const apiUrl = process.env.VUE_APP_API_URL
      const apiKey = process.env.VUE_APP_API_KEY;
      console.log('apikey:', apiKey)
      const headers = { 'x-api-key': apiKey, 'Content-Type': 'application/json' };
      const params = { venue: venueId };

      try {
        const response = await axios.get(apiUrl, { headers, params });
        this.menuData = response.data.data;
        console.log('Response:', this.menuData);
      } catch (error) {
        console.error('Error:', error);
      }
    },
  },
});
