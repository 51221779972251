<template>
    <div class="venue-details-main-container">
        <div>
            <div>
                <h2 class="restaurant-name">{{companyName}}</h2>
            </div>
            <div>
                <div>
                    <span></span>
                </div>
            </div>
            <div>
                <div>
                    <div class="info-item">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12 18c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4.24-2.58a.9959.9959 0 0 0-1.41 0c-1.73 1.73-4.52 1.73-6.24 0a.9959.9959 0 1 0-1.41 1.41c2.54 2.54 6.65 2.54 9.19 0a.9959.9959 0 0 0 0-1.41zM12 3c-4.42 0-8.48 1.72-11.59 4.84a.996.996 0 1 0 1.41 1.41C4.79 6.84 8.29 5.5 12 5.5s7.21 1.34 10.18 3.75a.996.996 0 1 0 1.41-1.41C20.48 4.72 16.42 3 12 3zm0 6c-2.97 0-5.74 1.18-7.83 3.1a.996.996 0 1 0 1.41 1.41A8.964 8.964 0 0 1 12 11c2.33 0 4.52.9 6.17 2.51a.996.996 0 1 0 1.41-1.41A10.944 10.944 0 0 0 12 9z"/>
                        </svg>
                        <span>{{`name: ${companyWifiDetails.username} | password: ${companyWifiDetails.password}`}}</span>
                    </div>
                    <div class="info-item">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z"></path>
                        </svg>
                        <a :href="companyNumber" rel="noreferrer noopener"><span>{{companyNumber}}</span></a>
                    </div>
                    <div class="info-item">
                        <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24">
                            <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                        </svg>
                        <a :href="companyWebsiteUrl" rel="noreferrer noopener" target="_blank"><span>{{companyWebsiteUrl}}</span></a>
                    </div>
                    <div class="info-item">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" stroke="currentColor" height="24" width="height" fill="currentColor">
                            <path d="M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z"></path><path d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z"></path><circle cx="390.476" cy="121.524" r="30.23"></circle>
                        </svg>
                        <a :href="companyInstagramUrl" rel="noreferrer noopener" target="_blank"><span>{{companyInstagramHandle}}/</span></a>
                    </div>
                    <div class="info-item">
                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M5.64 16.36a9 9 0 1 1 12.72 0l-5.65 5.66a1 1 0 0 1-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 1 0-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"></path>
                        </svg>
                        <a target="_blank" rel="noreferrer noopener">
                        <span>{{companyLocation}}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

//  eslint-disable-next-line
import { onMounted, watch, ref} from 'vue';
import { useMenuStore } from '../stores/menuDataStore';

// Initialize the menu store

const menuStore = useMenuStore();

let companyName = null
let companyInstagramUrl = null
let companyInstagramHandle = null
let companyWifiDetails = {}
let companyWebsiteUrl = null
let companyLocation = null
let companyNumber = null

watch(
  () => menuStore.menuData,
  (newMenuData) => {
    if (newMenuData) {
      companyName = newMenuData.company_name
      companyWebsiteUrl = newMenuData.company_website_url
      companyLocation = newMenuData.company_location
      companyInstagramUrl = newMenuData.company_instagram_url
      if (companyInstagramUrl){companyInstagramHandle = extractInstagramUsername(companyInstagramUrl)}
      const wifiDetails = newMenuData.company_wifi_details
      companyWifiDetails.username = wifiDetails.username
      companyWifiDetails.password = wifiDetails.password
      companyNumber = newMenuData.company_number
    }
  },
  { immediate: true }
);

// Function to extract the username from an Instagram URL
function extractInstagramUsername(url) {
  const urlParts = url.split('/');
  // Handle both cases: with and without the trailing slash
  return urlParts[urlParts.length - 1] || urlParts[urlParts.length - 2];
}

console.log("venueDetails Prop test:", companyName)

</script>

<style>
    .restaurant-name {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

    }
    .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    svg {
        fill: currentColor;
        margin-right: 15px;
        width: 24px;
        height: 24px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .venue-details-main-container{
        padding: 20px 20px
    }

</style>
