<template>
  <div class="product-spotlight">
    <div class="background-layer"></div>
    <div class="foreground-layer">
      <!-- Add content for the foreground layer here -->
      <h3>{{productListingSelected.name}}</h3>
      <img class="product-spotlight-image" :src="productListingSelected.image">
      <p>{{productListingSelected.description}}</p>
    </div>
  </div>
</template>


<script setup>
import { onMounted, defineProps } from 'vue';

const props = defineProps({
  productListingSelected: {
    type: Object,
    required: true,
    default: () => ({})
  }
});


onMounted (() => {
    console.log("Component mounted");
    console.log("Product Listing Selected:", props.productListingSelected);
}
)
// Initialize the menu store

</script>

<style scoped>
/* Ensure the spotlight covers the entire viewport */
.product-spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align the foreground layer to the bottom */
  z-index: 2000; /* Ensure it is above all other elements */
}

/* Background layer with translucent grey and blur effect */
.background-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Apply blur effect */
  z-index: 1000;
}

/* Foreground layer swooping up from the bottom */
.foreground-layer {
  position: relative;
  width: 100%;
  height: 66.66%; /* Cover two-thirds of the screen */
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1001;
  transform: translateY(100%); /* Start from below the screen */
  animation: swoop-up 0.3s ease-out forwards; /* Animation to swoop up */
  padding: 0; /* Adjust padding as needed */
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25); /* Add drop shadow */
  margin: 0px 20px 0px 20px
}

.product-spotlight-image{
    width: 100%; 
    padding: 0px;
    margin: 0px
}
/* Animation for swooping up */
@keyframes swoop-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
